<template>
  <div class="department-manage">
    <div class="main-content">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.department") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="showAddPopover"
          >
            {{ $t("department.add") }}
          </button>
          <!-- <button type="button" class="btn btn-primary btn-sm ml10" @click.stop="handleShowManagerPopover">批量设置权限</button> -->
        </div>
      </div>
      <div class="department-content">
        <div class="search-wrapper">
          <span class="search-btn" @click.stop="handleSearch">{{
            $t("search")
          }}</span>
          <el-form
            :inline="true"
            :model="searchForm"
            class="search-form"
            size="small"
          >
            <el-form-item :label="$t('department.department')">
              <el-input
                v-model="searchForm.name"
                clearable
                placeholder=" "
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('department.manager')">
              <el-input
                v-model="searchForm.department_administrator"
                clearable
                placeholder=" "
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="departmentList"
            :empty-text="$t('no_data')"
            style="width: 100%"
            header-row-class-name="thead-dark"
          >
            <el-table-column
              prop="name"
              :label="$t('student.department')"
            ></el-table-column>
            <el-table-column
              prop="administrator"
              :label="
                $t('department.manager') + '(' + $t('sign.username') + ')'
              "
            >
              <template v-slot="scope">
                <span>
                  {{ scope.row.administrator }} ({{ scope.row.username }})
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="doctor_count"
              :label="$t('department.doctor_count')"
            ></el-table-column>
            <el-table-column
              prop="student_count"
              :label="$t('department.student_count')"
            ></el-table-column>
            <el-table-column
              prop="project_count"
              :label="$t('department.project_count')"
            ></el-table-column>
            <el-table-column
              prop="patient_count"
              :label="$t('department.patient_count')"
            ></el-table-column>
            <el-table-column
              prop="updated_at"
              :label="$t('department.update_time')"
            ></el-table-column>
            <el-table-column :label="$t('department.status')">
              <template v-slot="scope">
                <span>{{ scope.row.status | formatStatus }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column :label="$t('department.right')">
              <template v-slot="scope">
                <router-link
                  :to="{ name: 'departmentAuthority', query: { id: scope.row.id, name: scope.row.name } }"
                  >{{ $t("department.edit_right") }}</router-link
                >
              </template>
            </el-table-column> -->
            <el-table-column :label="$t('operate')" align="center" width="150">
              <template v-slot="scope">
                <span
                  class="cursor text-primary mr10"
                  @click="showEditPopover(scope.row)"
                  >{{ $t("department.modify") }}</span
                >
                <span
                  class="cursor text-danger"
                  @click="handleDeleteDepartment(scope.row.id)"
                  >{{ $t("delete") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <edit-model
      :showPopover="showPopover"
      :department="department"
      :popoverTitle="popoverTitle"
      :isAdd="isAdd"
      @closePopover="closePopover"
      @submitDepartment="submitDepartment"
    >
    </edit-model>
  </div>
</template>

<script>
import {
  departmentListRequest,
  addDepartmentRequest,
  editDepartmentRequest,
  deleteDepartment,
} from "../../api/api";
import editModel from "../../components/department/editModel";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";

let _that;

export default {
  mixins: [paginationMixin],
  components: {
    editModel,
    pagination,
  },
  data() {
    return {
      searchForm: {
        name: "",
        department_administrator: "",
      },
      departmentList: [],
      showPopover: false,
      department: {},
      popoverTitle: "",
      popoverType: "",
      isAdd: true,
      showSearchDrawer: false,
      value1: "",
      clearable: false,
      showManagerPopover: false,
      pageSize: 15,
      currentPage: 1,
      totalCount: 0,
    };
  },
  created() {
    this.getDepartmentData();
  },
  methods: {
    handleSearch() {
      this.currentPage = 1;
      this.pageSize = 15;
      this.getDepartmentData();
    },
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getDepartmentData() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        ...this.searchForm,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      try {
        let res = await departmentListRequest(params);
        if (res.status === 200) {
          this.departmentList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total || 0;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    showAddPopover() {
      this.popoverTitle = this.$t("department.add");
      this.showPopover = true;
      this.popoverType = "add";
      this.isAdd = true;

      this.department = {
        name: "",
        administrator: "",
        email: "",
        password: "",
        username: "",
        title: "",
        telephone: "",
        analysisEnable: true,
      };
    },
    showEditPopover(department) {
      let analysisDisable =
        department.limits &&
        department.limits.analysis &&
        department.limits.analysis == "disable"
          ? true
          : false;

      console.log("analysisDisable", department, analysisDisable);
      this.department = {
        id: department.id || "",
        name: department.name || "",
        administrator: department.administrator || "",
        email: department.email || "",
        password: department.password || "",
        username: department.username || "",
        title: department.title || "",
        telephone: department.telephone || "",
        analysisEnable: !analysisDisable,
      };
      this.popoverTitle = this.$t("department.edit_department");
      this.showPopover = true;
      this.popoverType = "edit";
      this.isAdd = false;
    },
    closePopover() {
      this.department = {};
      this.popoverTitle = "";
      this.showPopover = false;
      this.popoverType = "";
    },
    submitDepartment(department) {
      if (this.popoverType === "add") {
        this.handleAddDepartment(department);
      } else if (this.popoverType === "edit") {
        this.handleEditDepartment(department);
      }
    },
    async handleAddDepartment(department) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      department.limits = department.analysisEnable
        ? {}
        : { analysis: "disable" };
      try {
        let res = await addDepartmentRequest(department);
        if (res.status === 200) {
          this.$helper.successNotify(res.message, function () {
            _self.getDepartmentData();
          });
          this.closePopover();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async handleEditDepartment(department) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let limits = department.analysisEnable ? {} : { analysis: "disable" };

      console.log("handleEditDepartment", department, limits);

      let params = {
        id: department.id,
        name: department.name,
        title: department.title,
        email: department.email,
        password: department.password,
        username: department.username,
        telephone: department.telephone,
        administrator: department.administrator,
        limits: limits,
      };
      try {
        let res = await editDepartmentRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDepartmentData();
          this.closePopover();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteDepartment(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteDepartment(id);
        })
        .catch(() => {});
    },
    async deleteDepartment(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let res = await deleteDepartment({ id: id });
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDepartmentData();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDepartmentData();
    },
    handleShowManagerPopover() {
      this.showManagerPopover = true;
    },
    handleHideManagerPover() {
      this.showManagerPopover = false;
    },
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("student.close");
        case "1":
          return _that.$t("student.open");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.department-manage {
  .el-checkbox {
    margin-bottom: 0;
    color: #fff;
  }
  .form-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
