<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body department-popover">
        <el-form
          :model="department"
          :rules="rules"
          ref="modelForm"
          label-width="140px"
          size="small"
        >
          <el-form-item :label="$t('student.department') + '：'" prop="name">
            <el-input v-model="department.name"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('department.manager') + '：'"
            prop="administrator"
          >
            <el-input v-model="department.administrator"></el-input>
          </el-form-item>
          <el-form-item :label="$t('department.email') + '：'" prop="email">
            <el-input v-model="department.email"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('department.init_password') + '：'"
            prop="password"
          >
            <el-input v-model="department.password"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('department.username') + '：'"
            prop="username"
          >
            <el-input v-model="department.username"></el-input>
          </el-form-item>
          <el-form-item :label="$t('department.title') + '：'">
            <el-input v-model="department.title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('department.tel_number') + '：'">
            <el-input v-model="department.telephone"></el-input>
          </el-form-item>
          <el-form-item
            :label="
              department.analysisEnable
                ? $t('service.enable_text')
                : $t('service.disable_text')
            "
          >
            <el-switch v-model="department.analysisEnable"></el-switch>
            <span></span>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitDepartment('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    department: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    popoverTitle: {
      type: String,
      default: "",
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t("validate.department"),
            trigger: "change",
          },
        ],
        administrator: [
          {
            required: true,
            message: this.$t("validate.department_manager"),
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("validate.email"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: this.isAdd,
            message: this.$t("validate.init_password"),
            trigger: "change",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t("validate.username"),
            trigger: "change",
          },
        ],
      };
    },
  },
  methods: {
    submitDepartment(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitDepartment", this.department);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    },
  },
};
</script>

<style lang="less">
.department-popover label {
  margin-bottom: 0;
}
</style>
